<template>
  <content-box title="About Us" class="col-md-7 col-xl-5">
    <p>
      Haverhill Hammers Youth Wrestling is real wrestling, not what you see on
      TV. Wrestling is one of the world's oldest sports. It's a tough sport that
      allows boys and girls of any size and weight to compete individually while
      being part of a team.
    </p>
    <p>
      Success isn't always determined by who is bigger or stronger, or even who
      has the most athletic ability. Success is often determined by a
      competitor's will to win and the size of their heart.
    </p>
    <p>
      Wrestling teaches discipline, hard work, commitment, sacrifice,
      perseverance and sportsmanship. It's the only sport in which competitors
      shake hands before AND after each match. Wrestling will instill qualities
      in your child that will serve them long after they leave our program.
    </p>
    <p>
      We are open to boys and girls, Kindergarten through 8th grade. The focus
      of our program is to to teach the fundamentals of folk style wrestling and
      give our athletes the mat experience they need to take their success to
      the next level when they enter high school.
    </p>
    <p>
      We have high expectations of our wrestlers. While we have fun in practice,
      our expectation is that kids come ready to work hard and learn. Our goal
      is to make sure everyone is competing to the best of their ability.
    </p>
  </content-box>
</template>

<script>
import contentBox from '@/components/contentBox'

export default {
  name: 'about',
  components: {
    contentBox,
  },
}
</script>

<style scoped></style>
